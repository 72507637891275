import React from "react"

const SubPageHeader = props => (
    <div className="SubPage-container">
        <h1 className="SubPage-title">{props.subPageTitle}</h1>
        <div className="SubPage-description">
            {props.subPageDescription}
        </div>
    </div>
);

export default SubPageHeader