import React from "react"
import Checkbox from "./Checkbox"
import avatar from "../../static/images/avatar.jpg"
import { Link } from "gatsby"

const ContactForm = () => (
        <div className="Contact-container" id='contact'>
            <div className="Contact-text">

                <h2>Kontakt</h2>
                <p>Ihr wollt noch mehr wissen und euch austauschen? Dann schreibt mir.</p>
            </div>
            <div className="Contact-form-box">
                <div className="Contact-form">
                    <form method="POST"
                          className="Contact-form"
                          name="contactHolisent"
                          data-netlify="true"
                          action="/success"
                          >
                        <input type="hidden" name="form-name" value="contactHolisent" />
                        <label className="Contact-form-label">
                            Name
                            <input className="Contact-form-input" type="text" autoComplete="name"
                                   placeholder="Name (benötigt)" name="Name" required/>
                        </label>
                        <label className="Contact-form-label">
                            E-Mail
                            <input className="Contact-form-input" type="email" autoComplete="email"
                                   placeholder="E-Mail (benötigt)" name="Email" required/>
                        </label>
                        <label className="Contact-form-label">
                            Nachricht
                            <textarea className="Contact-form-textarea"
                                      name="message" placeholder="Nachricht (benötigt)" required/>
                        </label>
                        <Checkbox
                            privacyDescription={<React.Fragment>Mit dem Absenden dieser Nachricht erklären Sie sich damit einverstanden, dass wir Ihre Daten ausschließlich zu Kontaktzwecken nutzen und speichern. Die Daten können auf Wunsch jederzeit gelöscht, widerrufen, eingesehen, transferiert und berichtigt werden. Nähere Informationen dazu entnehmen Sie bitte der <Link to='/impressum'>Datenschutzerklärung</Link>.</React.Fragment>}
                        />
                        <input type="submit" name="submit"
                               value="Nachricht absenden"
                               id="input-submit"
                               className="button blue"
                        />
                    </form>
                </div>
                <div className="Contact-form-person">
                    <img
                        className="Contact-form-avatar lazyload"
                        src={avatar}
                        alt="Erik Möser"
                    />
                    <p className="Contact-form-person-name">Erik Möser</p>
                </div>
            </div>
        </div>
    )
;

export default ContactForm