import React from "react"
/* eslint-disable */
const Checkbox = props => (
    <div>
        <small>
            <input style={{
                width: 'auto',
                float: 'left',
                marginTop: '8px',
                marginRight: '5px'
            }} id="acceptPrivacy" type="checkbox" required/>
            <label htmlFor="acceptPrivacy" className="Privacy-label">{props.privacyDescription}</label>
        </small>
    </div>
);

export default Checkbox