import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContactForm from "../components/ContactForm"
import PostPreview from "../components/PostPreview"
import SubPageHeader from "../components/SubPageHeader"
import {graphql} from 'gatsby'

class IndexPage extends React.Component {
    render() {
        const items = [];
        const posts = this.props.data.allMarkdownRemark.edges;
        posts.forEach((post) => {
            items.push(<PostPreview data={post} key={post.node.frontmatter.path}/>);
        });

        return (
            <Layout>
                <SEO title="Ideen finden" description="Hier werden Methoden vorgestellt, die dabei helfen, Denkblockaden zu lösen und Ideen zu generieren."/>
                <div className="SubPage-wrapper">
                    <SubPageHeader
                        subPageTitle="Ideen finden"
                        subPageDescription={<React.Fragment><p>„Man muss nicht nur mehr Ideen haben als andere, sondern auch die Fähigkeit besitzen, zu entscheiden, welche dieser Ideen gut sind.“ – Linus Pauling (Nobelpreisträger Chemie)</p>

<p>Am Anfang steht die zündende Idee. Aber was tun, wenn sich die richtige Idee partout nicht blicken lässt? Verschiedene Methoden helfen Euch, Denkblockaden zu überwinden. Einige davon stelle ich Euch hier vor.
    Aber seid Euch bewusst, dass Kreativitätstechniken die Qualität Eurer Ideen nicht verändern. Sie helfen nur, mehr Ideen in gleicher Zeit zu produzieren. Wenn Ihr dann mit diesen Ideen arbeitet und sie weiter anreichert, wird das Euch aber letztendlich auch zu qualitativ besseren Lösungsansätzen bringen.</p>
                        </React.Fragment>}
                    />
                    <div className="IndexPosts-container">
                        {items}
                    </div>
                </div>
                <ContactForm/>
            </Layout>
        );
    }
}

export default IndexPage;

export const pageQuery = graphql`
query IdeaQuery {
  allMarkdownRemark(filter: {frontmatter: {category: {eq: "ideen"}}}, sort: {order: DESC, fields: frontmatter___date}) {
    edges {
      node {
        timeToRead
        frontmatter {
          description
          date
          category
          image {
            childImageSharp {
                resize(quality: 100, width: 800) {
                    src
                }
            }
          }
          path
          title
        }
      }
    }
  }
}
`;
